import { FlexColumn, FlexColumnJustifyCenter, FlexRowAlignCenter } from "@/components/Flex";
import { ASSETS_URL } from "@/config/deployConstants";
import { borderRadius } from "@/utils/borderRadius";
import { spacing } from "@/utils/spacing";
import { themeColors } from "@/utils/themeColors";
import Image from "next/image";

export const ReviewCard = ({
    title,
    review,
    style,
}: {
    title: string;
    review: string;
    style?: React.CSSProperties;
}) => (
    <FlexColumn
        style={{
            gap: spacing.XS,
            padding: spacing.MD,
            backgroundColor: themeColors.neutralWhite,
            borderRadius: borderRadius.smallModal,
            width: "100%",
            flex: 1,
            margin: "auto",
            alignSelf: "stretch",
            overflow: "hidden",
            ...style,
        }}>
        <FlexColumnJustifyCenter
            style={{
                gap: spacing.XS_2,
                textAlign: "center",
                alignItems: "center",
            }}>
            <FlexRowAlignCenter
                style={{
                    gap: "0.2rem",
                    justifyContent: "center",
                }}>
                {new Array(5).fill(0).map((_, index) => (
                    <Image src={`${ASSETS_URL}/images/star-yellow.png`} alt="star" key={index} width={22} height={22} />
                ))}
            </FlexRowAlignCenter>
            <p className="bodyBold1" style={{ margin: 0, maxWidth: "25rem", fontSize: "16px" }}>
                {title}
            </p>
        </FlexColumnJustifyCenter>
        <p
            className="secondaryText2"
            style={{
                margin: 0,
                lineClamp: 5,
                fontSize: "14px",
                overflow: "hidden",
                textAlign: "center",
                wordBreak: "break-word",
            }}>
            {review}
        </p>
    </FlexColumn>
);

export enum ReviewType {
    TEACHERS = "TEACHERS",
    AI = "AI",
    AP = "AP",
    HOMEPAGE = "HOMEPAGE",
    SCHOOLS = "SCHOOLS",
}

export type Review = {
    title: string;
    desc: string;
};

export const USER_REVIEWS: Record<ReviewType, Review[]> = {
    [ReviewType.TEACHERS]: [
        {
            title: "Way more worth it.",
            desc: "Knowt lets me keep track of exactly what my students need more practice on and makes lesson planning easier for me!",
        },
        {
            title: "Can't believe I used to pay for Quizlet.",
            desc: "I recently switched to Knowt & they have so many more features. I use Knowt for all classes now & students love it.",
        },
        /* {
            title: "Easy to switch",
            desc: "I switched over to knowt and I'm so glad to say I haven't regretted it one bit. They have an extension so I moved all my Quizlets over fairly quickly.",
        }, */
        {
            title: "School blocked Quizlet.",
            desc: "I found Knowt after my school blocked Quizlet & it was so easy to get set up. Their team worked directly with our IT co-ord to answer all their questions.",
        },
        {
            title: "Get this app, it's amazing!",
            desc: "Never in my life have I done a review for any app but I just love knowt so much. I've recommended it to all my friends and they all agree that it's a life saver.",
        },
        /* {
            title: "Great for younger students too",
            desc: "My kids love to play the matching game in class, and it's so easy for me to keep track of their progress when I assign it for homework.",
        }, */
        {
            title: "Awesome app!",
            desc: "You can tell they put thought into making this perfect for teachers. There's so many optimized features you won't find on Quizlet or Google Classroom",
        },
        {
            title: "Excellent review tool for students.",
            desc: "This app is very simple to use and engages students with different types of learning to reinforce the material. It's fun and easy for them.",
        },
    ],
    [ReviewType.AI]: [
        {
            title: "Great for last minute prep",
            desc: "10/10 recommend. I love the ai feature of summarizing lectures and pdfs and makes note taking a blast.",
        },
        {
            title: "Saved my BUTT",
            desc: "these guys seriously saved me. i'm not a good student by any means and this is the only reason i passed my classes",
        },
        {
            title: "Amazing!",
            desc: "I love all the AI-related features, the fact that it has an active recall feature, and the easy importing of notes, videos or even pre-existing quizlets. I love it and I'll be using this forever.",
        },
        {
            title: "Absolute tool for studies",
            desc: "It has made studying go by so much faster and easier. everything about the app is easy to use, and it doesn't have just AP exams",
        },
        {
            title: "Quizlet's cheaper, younger, hotter, cooler sister!!",
            desc: "I love Knowt, the free version is much better than Quizlet. It's a great app and I highly recommend it",
        },
        {
            title: "Get this app, it's amazing!",
            desc: "never in my life have I done a review for any app but I just love knowt so much. I've recommended it to all my friends and they all agree that it's a life saver.",
        },
        {
            title: "I love this app",
            desc: "This app has amazing features, and has helped me soo much with a lot of my classwork. Handsdown best study app. They also have amazing customer service!",
        },
        {
            title: "NEEDS to be in your school survival kit ",
            desc: "i hope anyone reading this can join me and many other users in switching from quizlet or other competitors to knowt! thank you so much for everything, knowt! truly one of the best apps ever.",
        },
        {
            title: "LITERAL SAVING GRACE",
            desc: "Knowt it the ONLY reason i got a 5 on the AP Human Geo exam without them i would have probably gotten a one.",
        },
    ],
    [ReviewType.AP]: [
        {
            title: "LITERAL SAVING GRACE",
            desc: "Knowt it the ONLY reason i got a 5 on the AP Human Geo exam without them i would have probably gotten a one.",
        },
        {
            title: "Amazing app!",
            desc: "The multiple choice practices on here were very similar to those on the exam and they truly helped me. Definitely recommend!",
        },
        {
            title: "BEST APP EVER",
            desc: "I did not study at all for APUSH on my own. I only did the multiple choice knowt offers for a week straight and got a 5. without this app I would've gotten a 2 😭🙏",
        },
        {
            title: "100% worth downloading",
            desc: "This app saved me a lot with AP US History and is the reason I got a 5, and passed my AP precalc exam. Super helpful with flash cards and explanations! : )",
        },
        {
            title: "Best App for AP Season!!!",
            desc: "I just got my scores back and I passed all 5 APs because of knowt. Even with cramming last minute. 🥳",
        },
        {
            title: "knowt literally saved me",
            desc: "i got a 5!! having the MCQ practice was amazing. the prompts for the written portion were so nice; they were challenging yet easy to understand and were similar to the ones on the test.",
        },
        {
            title: "i could marry this app",
            desc: "thought i had no hope in passing my APs but after I downloaded this app not even 15 days before the exam i somehow managed to get a 4 on ap macro?? this app does miracles",
        },
        {
            title: "saved by ap score",
            desc: "OH MY GOD THIS JS THE MOST USEFUL APP EVER. when i was studying for my ap test it was so useful to be able to have it auto grade frq's and give unique mcq's cause  the college board ones are so repetitive.",
        },
        {
            title: "I love it",
            desc: "My favorite part about this app is the practice multiple choice tests. Not only are you able to customize the tests to include specific units and material, but you're also able to see why an answer is correct or incorrect.",
        },
    ],
    [ReviewType.HOMEPAGE]: [
        {
            title: "a lifesaver in studying",
            desc: "even if Quizlet had not ceased to be a free service, I have to say I would still choose Knowt over it. overall, it's a fantastic resource and perfect for students.",
        },
        {
            title: "saved my BUTT",
            desc: "these guys seriously saved me. i'm not a good student by any means and this is the only reason i passed my classes",
        },
        {
            title: "such a great & reliable resource!!",
            desc: "I switched over to knowt and i'm so glad to say i haven't regretted it one bit. i use knowt for each every one of my quizzes, tests, and exams!",
        },
        {
            title: "Quizlet's cheaper, younger, hotter, cooler sister!!",
            desc: "I love Knowt, the free version is much better than Quizlet. It's a great app and I highly recommend it",
        },
        {
            title: "get this app, it's amazing!",
            desc: "never in my life have I done a review for any app but I just love knowt so much. I've recommended it to all my friends and they all agree that it's a life saver.",
        },
        {
            title: "Marry me Kai 💍",
            desc: "If Knowt has 100 fans, I'm one of them. If Knowt has 1 fan, it's me. If Knowt has no fans, I'm probably dead. I love this app/website sm.",
        },
        {
            title: "Amazing app!",
            desc: "Has so many great pre-made resources like notes and flashcards and it's all free to use, unlike some other flashcard apps (I'm looking at you Quizlet). I'm so grateful to the developers of Knowt, thank you!",
        },
        {
            title: "NEEDS to be in your school survival kit ",
            desc: "i hope anyone reading this can join me and many other users in switching from quizlet or other competitors to knowt! thank you so much for everything, knowt! truly one of the best apps ever.",
        },
        {
            title: "LITERAL SAVING GRACE",
            desc: "Knowt it the ONLY reason i got a 5 on the AP Human Geo exam without them i would have probably gotten a one.",
        },
    ],
    [ReviewType.SCHOOLS]: [
        {
            title: "Saves me time",
            desc: "This makes lesson planning so much faster for me! Definetely recommend the feedback tool.",
        },
        {
            title: "My kids love it!",
            desc: "The lecture note taker tool is a game-changer. I can just send my student organized, effective study guides at the end of each class.",
        },
        {
            title: "Great for engagement",
            desc: "Helped make my classroom more interactive. My students are more engaged and excited to learn!",
        },
        {
            title: "Get this app, it’s amazing!",
            desc: "Never in my life have I done a review for any app but I just love knowt so much. I’ve recommended it to all my friends and they all agree that it’s a life saver.",
        },
        {
            title: "Great for younger students too",
            desc: "My kids love to play the matching game in class, and it’s so easy for me to keep track of their progress when I assign it for homework.",
        },
        {
            title: "Excellent review tool for students.",
            desc: "This app is very simple to use and engages students with different types of learning to reinforce the material. It’s fun and easy for them.",
        },
        {
            title: "School blocked Quizlet.",
            desc: "I found Knowt after my school blocked Quizlet & it was so easy to get set up. Their team worked directly with our IT co-ord to answer all their questions.",
        },
        {
            title: "Awesome app!",
            desc: "You can tell they put thought into making this perfect for teachers. There’s so many optimized features you won’t find on Quizlet or Google Classroom",
        },
        {
            title: "Way more worth it",
            desc: "Knowt lets me keep track of exactly what my students need more practice on and makes lesson planning easier for me!",
        },
    ],
};

import { FlexRowAlignCenter, FlexColumnAlignJustifyCenter, FlexColumn } from "@/components/Flex";
import LinkWrapper from "@/components/wrappers/Link";
import { borderRadius } from "@/utils/borderRadius";
import { themeColors } from "@/utils/themeColors";
import { spacing } from "@/utils/spacing";
import { Library } from "lucide-react";
import { Hourglass } from "lucide-react";
import { BookCheck, MessageCircle, TextSearch, BrainCircuit } from "lucide-react";
import { DefaultBenefits } from "../utils/constants";

const icon0 = <Library />;
const icon1 = <Hourglass />;
const icon2 = <BrainCircuit />;
const icon3 = <BookCheck />;
const icon4 = <TextSearch />;
const icon5 = <MessageCircle />;

const icons = [icon0, icon1, icon2, icon3, icon4, icon5];

const colors = [
    themeColors.pdfLight,
    themeColors.excelLight,
    themeColors.videoLight,
    themeColors.docsLight,
    "#F8E2FA",
    "#F8E2FA",
];

const BenefitsCards = ({
    benefits,
    style,
}: {
    benefits: {
        title: string;
        desc: string;
        img: React.ReactNode;
        color: string;
        button?: { href: string; text: string };
    }[];
    style?: React.CSSProperties;
}) => {
    return (
        <>
            {(benefits || DefaultBenefits).map((b, i) => (
                <FlexColumn
                    key={`benefit-${i}`}
                    style={{
                        flex: 1,
                        minWidth: "30%",
                        gap: spacing.MD,
                        padding: spacing.MD,
                        borderRadius: borderRadius.card,
                        backgroundColor: themeColors.neutralWhite,
                        ...style,
                    }}>
                    <FlexColumnAlignJustifyCenter
                        style={{
                            backgroundColor: b.color ?? colors[i],
                            width: "6rem",
                            height: "6rem",
                            borderRadius: "11px",
                        }}>
                        {b.img ?? icons[i]}
                    </FlexColumnAlignJustifyCenter>
                    <span className="bodyBold1" style={{ fontSize: "16px" }}>
                        {b.title}
                    </span>
                    <FlexColumn style={{ flex: 1, justifyContent: "center", gap: spacing.MD }}>
                        <p className="secondaryText1">{b.desc}</p>
                    </FlexColumn>
                    {b.button && (
                        <LinkWrapper href={b.button.href}>
                            <FlexRowAlignCenter
                                className="bodyBold2"
                                style={{
                                    backgroundColor: themeColors.neutralBlack,
                                    color: themeColors.neutralWhite,
                                    borderRadius: borderRadius.shortInput,
                                    width: "fit-content",
                                    padding: "1rem 2.4rem",
                                }}>
                                {b.button.text}
                            </FlexRowAlignCenter>
                        </LinkWrapper>
                    )}
                </FlexColumn>
            ))}
        </>
    );
};

export default BenefitsCards;

import { Flex, FlexColumn } from "@/components/Flex";
import { ReviewCard } from "@/features/Payments/components/ReviewCard";
import br from "@/styles/breakpoints.module.css";
import { spacing } from "@/utils/spacing";

interface User {
    title: string;
    desc: string;
}

interface FeedbackCardProps {
    users: User[];
    style?: React.CSSProperties;
    gridPattern?: [number, number][];
    reviewCardStyle?: React.CSSProperties;
}

export const FeedbackCard = ({
    users,
    style,
    gridPattern = [
        [0, 3],
        [3, 6],
        [6, 9],
    ],
    reviewCardStyle,
}: FeedbackCardProps) => {
    return (
        <Flex style={{ gap: spacing.SM, alignItems: "flex-start", overflow: "hidden", ...style }}>
            {gridPattern.map(([start, end], index) => (
                <FlexColumn
                    key={index}
                    className={br.mdDownDisplayNone}
                    style={{ gap: "inherit", flex: 1, flexGrow: 1 }}>
                    {users?.slice(start, end).map((user, i) => (
                        <ReviewCard
                            key={`${user.title}_${i}`}
                            title={user.title}
                            review={user.desc}
                            style={reviewCardStyle}
                        />
                    ))}
                </FlexColumn>
            ))}
        </Flex>
    );
};

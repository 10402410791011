import React from "react";
import clsx from "clsx";
import { ACCENT_COLOR_LIGHT_PROP, ACCENT_COLOR_DARK_PROP } from "../../utils";
import styles from "../../landing.module.css";
import { Flex, FlexColumn, FlexColumnAlignJustifyCenter } from "@/components/Flex";
import { themeColors } from "@/utils/themeColors";
import Sidebar from "@/features/Sidebar";
import NavBar from "@/features/NavBar/NavBar";
import Box from "@mui/material/Box";
import { InnerContainer } from "@/components/Containers";

/**
 * @deprecated
 * it's should not be used anymore as our new landing pages now does not behave like this component
 * prefer:
 * ```tsx
 * <LandingFullWidthSection>
 *   <LandingHalfSection>{children}</LandingHalfSection>
 * </LandingFullWidthSection>
 * ```
 */
export const LandingMainContainer = ({
    children,
    style,
    className,
    accentColorLight,
    accentColorDark,
    ...divProps
}: {
    accentColorLight: string;
    accentColorDark: string;
} & React.HTMLAttributes<HTMLDivElement>) => {
    return (
        <FlexColumn
            className={clsx(styles.mainContainer, className)}
            {...divProps}
            style={
                {
                    [ACCENT_COLOR_LIGHT_PROP]: accentColorLight,
                    [ACCENT_COLOR_DARK_PROP]: accentColorDark,
                    backgroundColor: themeColors.neutralWhite,
                    ...style,
                } as React.CSSProperties
            }>
            {children}
        </FlexColumn>
    );
};

/** Same like MainContainer but for landing pages */
export const LandingPageWrapper = ({
    children,
    className,
    style,
    landing = false,
    footerAds = false,
    ...divProps
}: { landing?: boolean; footerAds?: boolean } & React.HTMLAttributes<HTMLDivElement>) => {
    return (
        <Flex>
            <Sidebar footerAds={footerAds} />
            <div style={{ flex: 1, width: "50%" }}>
                <NavBar landing={landing} />
                <Flex style={{ flex: 1, ...style }} {...divProps}>
                    <InnerContainer style={{ width: "100%", margin: "0", maxWidth: "100%" }}>{children}</InnerContainer>
                </Flex>
            </div>
        </Flex>
    );
};

/**
 * If a section of landing has half width but the bg takes up full width, wrap this component before `LandingHalfSection`.
 * @link For example this UI on main landing page: https://www.figma.com/design/CFUR7NUDX9j8X4TRBqw8ag/Web-(Redesign)?node-id=37670-787&m=dev
 */
export const LandingFullWidthSection = ({ children, style, ...divProps }: React.HTMLAttributes<HTMLDivElement>) => {
    return (
        <FlexColumn className={styles.mdDownSidePadding} style={{ ...style }} {...divProps}>
            {children}
        </FlexColumn>
    );
};

export const LandingHalfSection = ({
    className,
    children,
    style,
    ...divProps
}: React.HTMLAttributes<HTMLDivElement>) => {
    return (
        <FlexColumnAlignJustifyCenter
            as={Box}
            component={"section"}
            sx={{
                width: "100%",
                maxWidth: "104rem",
                margin: "0 auto",
                "& > p, & > h1, & > h2, & > h3, & > h4, & > h5, & > h6": {
                    textAlign: "center",
                    maxInlineSize: "86rem",
                },
                ...style,
            }}
            {...divProps}>
            {children}
        </FlexColumnAlignJustifyCenter>
    );
};

import CircularRectTextButton from "@/components/CircularButton/styled/CircularRectTextButton";
import { FlexRowAlignCenter } from "@/components/Flex";

import { FlexColumnAlignJustifyCenter } from "@/components/Flex";
import LinkWrapper from "@/components/wrappers/Link";
import { ASSETS_URL } from "@/config/deployConstants";
import { borderRadius } from "@/utils/borderRadius";
import { spacing } from "@/utils/spacing";
import { themeColors } from "@/utils/themeColors";
import { ArrowRight } from "lucide-react";
import Image from "next/image";

const cardsColors = ["#FCEDD5", "#F0F5F2"];

const BrowseEncouragementCards = ({
    encouragementCards,
}: {
    encouragementCards: {
        title: string;
        href: string;
        image: string;
    }[];
}) => {
    return (
        <>
            {encouragementCards.map((card, i) => (
                <LinkWrapper key={`encouragement-card-${i}`} href={card.href} style={{ width: "100%" }}>
                    <FlexColumnAlignJustifyCenter
                        style={{
                            width: "100%",
                            height: "100%",
                            padding: "3rem",
                            gap: spacing.XS,
                            borderRadius: borderRadius.card,
                            backgroundColor: cardsColors[i],
                        }}>
                        <FlexRowAlignCenter style={{ justifyContent: "space-between", width: "100%" }}>
                            <span className="heading3" style={{ maxWidth: "30rem", color: themeColors.pureBlack }}>
                                {encouragementCards?.[i]?.title ?? "Become a Knowt Knight & earn!"}
                            </span>
                            <CircularRectTextButton
                                component={"div"}
                                style={{
                                    backgroundColor: themeColors.pureBlack,
                                    padding: spacing.XS,
                                    borderRadius: borderRadius.circular,
                                }}>
                                <ArrowRight
                                    size={46}
                                    color={themeColors.pureWhite}
                                    style={{ transform: "rotate(-45deg)" }}
                                />
                            </CircularRectTextButton>
                        </FlexRowAlignCenter>
                        <Image
                            src={encouragementCards?.[i]?.image ?? `${ASSETS_URL}/images/knowt-ambassador-benefits.svg`}
                            alt={"ai tool"}
                            width={420}
                            height={200}
                            style={{ flexShrink: 0, width: "100%" }}
                        />
                    </FlexColumnAlignJustifyCenter>
                </LinkWrapper>
            ))}
        </>
    );
};

export default BrowseEncouragementCards;
